import React, { useEffect } from "react";

import DashboardSkeleton from "./dashboard";

const AuthRedirect = ({ location, navigate }) => {
	const supposedToBeHere =
		location.search.indexOf("code") >= 0 &&
		location.search.indexOf("state") >= 0;

	useEffect(() => {
		if (!supposedToBeHere) {
			navigate("/");
		}
	}, [supposedToBeHere, navigate]);

	if (!supposedToBeHere) return null;

	return <DashboardSkeleton location={location} />;
};

export default AuthRedirect;
